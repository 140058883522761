
body {
  margin: 0;
  font-family: 'Proxima Nova', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.medium, p.medium {
  font-weight: 400;
}

.bold, p.bold {
  font-weight: 700;
}

.boldest, p.boldest {
  font-weight: 900;
}

/* DRAG AND DROP (LISTVIEW) STYLING */

.dropLine {
  border: 1px dashed #bdbdbd !important;
}

/* EDITOR STYLING */

.editor-container {
  line-height: 1.6;
  text-align: left;
  width: 100%;
  max-width: 780px;
  background-color: #ffffff;
  padding: 0px;
  margin: 0px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 900px) { .editor-container { width: 680px; } }
@media only screen and (max-width: 800px) { .editor-container { width: 600px; } }
@media only screen and (max-width: 700px) { .editor-container { width: 460px; } }
@media only screen and (max-width: 500px) { .editor-container { width: 360px; } }
@media only screen and (max-width: 400px) { .editor-container { width: 320px; } }

.editor-container * {
  font-family: 'Proxima Nova', sans-serif;
}

.editor-input {
  min-height: 450px;
  resize: none;
  font-size: 16px;
  position: relative;
  tab-size: 1;
  outline: 0;
  caret-color: rgb(5, 5, 5);
}

.editor-paragraph {
  margin: 0;
  padding: 0;
  position: relative;
}

.editor-heading-h1 {
  font-size: 30px;
  font-weight: 700;
  color: rgb(5, 5, 5);
  margin: 0;
  padding: 0;
}

.editor-heading-h2 {
  font-size: 24px;
  color: rgb(5, 5, 5);
  font-weight: bold;
  margin: 0;
  padding: 0;
}


  /*
  background-color: #f6f6f6;
  font-weight: 300;
  position: relative;
  margin: 20px auto 20px auto;
  color: #000;
  max-width: 600px;
  border-radius: 2px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;*/

/*
.editor-container {
  line-height: 1.6;
  text-align: left;
  width: 100%;
  max-width: 780px;
  background-color: #ffffff;
  padding: 0px;
  margin: 0px
}

.editor-container * {
  font-family: 'Proxima Nova', sans-serif;
}*/

/*

.editor-inner {
  background: #fff;
  position: relative;
}
*/

/*
*/

/*
.other h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 7px;
}

.other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}*/

/*
.App {
  font-family: sans-serif;
  text-align: center;
}*/

/*
h1 {
  font-size: 24px;
  color: #333;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

*/

/*
.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}*/


/*
.editor-text-code {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}*/
/*
.editor-link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}*/

/*
.tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  white-space: pre-wrap;
  margin: 1px auto 10px auto;
  max-height: 250px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
  line-height: 14px;
}*/

/*
.editor-code {
  background-color: rgb(240, 242, 245);
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  tab-size: 2;
  overflow-x: auto;
  position: relative;
}

.editor-code:before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}
.editor-code:after {
  content: attr(data-highlight-language);
  top: 0;
  right: 3px;
  padding: 3px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
}

.editor-tokenComment {
  color: slategray;
}

.editor-tokenPunctuation {
  color: #999;
}

.editor-tokenProperty {
  color: #905;
}

.editor-tokenSelector {
  color: #690;
}

.editor-tokenOperator {
  color: #9a6e3a;
}

.editor-tokenAttr {
  color: #07a;
}

.editor-tokenVariable {
  color: #e90;
}

.editor-tokenFunction {
  color: #dd4a68;
}*/
/*
.editor-paragraph {
  margin: 0;
  margin-bottom: 0px;
  position: relative;
}*/

/*
.editor-paragraph:last-child {
  margin-bottom: 0;
}*/

/*
.editor-heading-h1 {
  font-size: 30px;
  font-weight: 700;
  color: rgb(5, 5, 5);
  margin: 0;
  padding: 0;
}

.editor-heading-h2 {
  font-size: 24px;
  color: rgb(5, 5, 5);
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.editor-heading-h3 {
  font-size: 18px;
  color: rgb(5, 5, 5);
  font-weight: bold;
  margin: 0;
  padding: 0;
}*/

/*
.editor-quote {
  margin: 0;
  margin-left: 20px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}*/

/*
.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-listitem {
  margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
  list-style-type: none;
}*/

/* COMMENT PLUGIN */

.CommentPlugin_AddCommentBox {
  display: block;
  position: fixed;
  border-radius: 20px;
  background-color: white;
  width: 40px;
  height: 60px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.CommentPlugin_AddCommentBox_button {
  border-radius: 20px;
  border: 0;
  background: none;
  width: 40px;
  height: 60px;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.CommentPlugin_AddCommentBox_button:hover {
  background-color: #f6f6f6;
}

.CommentPlugin_CommentInputBox {
  display: block;
  position: fixed;
  width: 260px;
  min-height: 80px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 24px 0px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  z-index: 600;
  animation: show-input-box 0.4s ease;
}

.CommentPlugin_CommentInputBox::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 0.5em;
  right: -1em;
  top: 0;
  left: calc(50% + 0.25em);
  box-sizing: border-box;
  border: 0.5em solid black;
  border-color: transparent transparent #7243dd #7243dd;
  transform-origin: 0 0;
  transform: rotate(135deg);
  /*box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.05);*/
  box-shadow: rgba(0, 0, 0, 0.05) -3px 3px 24px 0px
}

@keyframes show-input-box {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.CommentPlugin_CommentInputBox_Buttons {
  display: flex;
  flex-direction: row;
  padding: 0 10px 10px 10px;
  gap: 5px;
}

/*
  justify-content: flex-end;


.CommentPlugin_CommentInputBox_Button {
  flex: 1;
}

.CommentPlugin_CommentInputBox_Button.primary {
  background-color: rgb(66, 135, 245);
  font-weight: bold;
  color: #fff;
}

.CommentPlugin_CommentInputBox_Button.primary:hover {
  background-color: rgb(53, 114, 211);
}

.CommentPlugin_CommentInputBox_Button[disabled] {
  background-color: #eee;
  opacity: 0.5;
  cursor: not-allowed;
  font-weight: normal;
  color: #444;
}

.CommentPlugin_CommentInputBox_Button[disabled]:hover {
  opacity: 0.5;
  background-color: #eee;
}*/

.CommentPlugin_CommentInputBox_EditorContainer {
  position: relative;
  margin: 0px;
  border-radius: 5px;
}

/*
.CommentPlugin_CommentInputBox_Editor {
  position: relative;
  border: 1px solid #ccc;
  background-color: #ff0000;
  border-radius: 5px;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  display: block;
  padding: 9px 10px 10px 9px;
  min-height: 80px;
}

.CommentPlugin_CommentInputBox_Editor:focus {
  outline: 1px solid rgb(66, 135, 245);
}*/

.CommentPlugin_ShowCommentsButton {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #ddd;
  border-radius: 10px;
}

/*.CommentPlugin_ShowCommentsButton:hover i.comments {
  opacity: 1;
}*/

.CommentPlugin_ShowCommentsButton.active {
  background-color: #ccc;
}

.CommentPlugin_CommentsPanel {
  position: fixed;
  right: 0;
  width: 320px;
  height: calc(100% - 88px);
  top: 88px;
  background-color: #fff;
  border-top-left-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: show-comments 0.2s ease;
  z-index: 25;
}

@keyframes show-comments {
  0% {
    opacity: 0;
    transform: translateX(320px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.CommentPlugin_CommentsPanel_Heading {
  padding-left: 15px;
  padding-top: 10px;
  margin: 0;
  height: 34px;
  border-bottom: 1px solid #eee;
  font-size: 20px;
  display: block;
  width: 100%;
  color: #444;
  overflow: hidden;
}

.CommentPlugin_CommentsPanel_Footer {
  border-top: 1px solid #eee;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.CommentPlugin_CommentsPanel_Editor {
  position: relative;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  display: block;
  padding: 9px 10px 10px 9px;
  min-height: 20px;
}

.CommentPlugin_CommentsPanel_Editor::before {
  content: '';
  width: 30px;
  height: 20px;
  float: right;
}

.CommentPlugin_CommentsPanel_SendButton {
  position: absolute;
  right: 10px;
  top: 8px;
  background: none;
}

.CommentPlugin_CommentsPanel_SendButton:hover {
  background: none;
}

/*
.CommentPlugin_CommentsPanel_SendButton:hover i.send {
  opacity: 1;
  filter: invert(45%) sepia(98%) saturate(2299%) hue-rotate(201deg)
    brightness(100%) contrast(92%);
}

.CommentPlugin_CommentsPanel_SendButton[disabled] i.send {
  opacity: 0.3;
}

.CommentPlugin_CommentsPanel_SendButton:hover[disabled] i.send {
  opacity: 0.3;
  filter: none;
}*/

.CommentPlugin_CommentsPanel_Empty {
  color: #777;
  font-size: 15px;
  text-align: center;
  position: absolute;
  top: calc(50% - 15px);
  margin: 0;
  padding: 0;
  width: 100%;
}

.CommentPlugin_CommentsPanel_List {
  padding: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  position: absolute;
  top: 45px;
  overflow-y: auto;
}

.CommentPlugin_CommentsPanel_List_Comment {
  padding: 15px 0 15px 15px;
  margin: 0;
  font-size: 14px;
  position: relative;
  transition: all 0.2s linear;
}

.CommentPlugin_CommentsPanel_List_Thread.active
  .CommentPlugin_CommentsPanel_List_Comment:hover {
  background-color: inherit;
}

.CommentPlugin_CommentsPanel_List_Comment p {
  margin: 0;
  color: #444;
}

.CommentPlugin_CommentsPanel_List_Details {
  color: #444;
  padding-bottom: 5px;
  vertical-align: top;
}

.CommentPlugin_CommentsPanel_List_Comment_Author {
  font-weight: bold;
  padding-right: 5px;
}

.CommentPlugin_CommentsPanel_List_Comment_Time {
  color: #999;
}

.CommentPlugin_CommentsPanel_List_Thread {
  padding: 0 0 0 0;
  margin: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  position: relative;
  transition: all 0.2s linear;
  border-left: 0 solid #eee;
}

.CommentPlugin_CommentsPanel_List_Thread:first-child,
.CommentPlugin_CommentsPanel_List_Thread
  + .CommentPlugin_CommentsPanel_List_Thread {
  border-top: none;
}

.CommentPlugin_CommentsPanel_List_Thread.interactive {
  cursor: pointer;
}

.CommentPlugin_CommentsPanel_List_Thread.interactive:hover {
  background-color: #fafafa;
}

.CommentPlugin_CommentsPanel_List_Thread.active {
  background-color: #fafafa;
  border-left: 15px solid #eee;
  cursor: inherit;
}

.CommentPlugin_CommentsPanel_List_Thread_Quote {
  padding-top: 10px;
  margin: 0px 10px 0 10px;
  color: #ccc;
  display: block;
}

.CommentPlugin_CommentsPanel_List_Thread_Quote span {
  color: #222;
  background-color: rgba(255, 212, 0, 0.4);
  padding: 1px;
  line-height: 1.4;
  display: inline;
  font-weight: bold;
}

.CommentPlugin_CommentsPanel_List_Thread_Comments {
  padding-left: 10px;
  list-style-type: none;
}

.CommentPlugin_CommentsPanel_List_Thread_Comments
  .CommentPlugin_CommentsPanel_List_Comment:first-child {
  border: none;
  margin-left: 0;
  padding-left: 5px;
}

.CommentPlugin_CommentsPanel_List_Thread_Comments
  .CommentPlugin_CommentsPanel_List_Comment:first-child.CommentPlugin_CommentsPanel_List_Comment:last-child {
  padding-bottom: 5px;
}

.CommentPlugin_CommentsPanel_List_Thread_Comments
  .CommentPlugin_CommentsPanel_List_Comment {
  padding-left: 10px;
  border-left: 5px solid #eee;
  margin-left: 5px;
}

.CommentPlugin_CommentsPanel_List_Thread_Editor {
  position: relative;
  padding-top: 1px;
}

.CommentPlugin_CommentsPanel_List_DeleteButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  opacity: 0;
}

.CommentPlugin_CommentsPanel_List_Comment:hover
  .CommentPlugin_CommentsPanel_List_DeleteButton {
  opacity: 0.5;
}

.CommentPlugin_CommentsPanel_List_DeleteButton:hover {
  background-color: transparent;
  opacity: 1;
  filter: invert(45%) sepia(98%) saturate(2299%) hue-rotate(201deg)
    brightness(100%) contrast(92%);
}

/* COMMENT EDITOR THEME */

.CommentEditorTheme__paragraph {
  margin: 0;
  position: 'relative';
}

/* COMMENT PLAIN TEXT EDITOR */
.ContentEditable__root {
  height: 140px;
  border: 0;
  resize: none;
  cursor: text;
  font-size: 15px;
  display: block;
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 5px 20px 5px 20px;
  overflow: auto;
}

.ContentEditable__rootReply {
  /*height: 80px;*/
  min-height: 75px;
  border: 0;
  resize: none;
  cursor: text;
  font-size: 14px;
  display: block;
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 0px 20px 0px 20px;
  overflow: auto;
}

.Placeholder__root {
  font-size: 15px;
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 20px;
  left: 24px;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
}

/* MARK STYLING */
.__markComment {
  background: rgba(255, 212, 0, 0.15);
  border-bottom: 2px solid rgba(255, 212, 0, 0.3);
  padding-bottom: 2px;
}

.__markComment.selected {
  background: rgba(255, 212, 0, 0.5);
  border-bottom: 2px solid rgba(255, 212, 0, 1);
}

.__markCommentInternal {
  /*background: rgba(247, 140, 0, 0.15);*/
  background: rgba(0, 255, 255, 1.0);
  /*border-bottom: 2px solid rgba(247, 140, 0, 0.4);
  padding-bottom: 2px;*/
}

.__markCommentInternal.selected {
  /*background: rgba(255, 212, 0, 0.5);*/
  background: rgba(0, 255, 255, 1.0);
  /*border-bottom: 2px dotted rgba(247, 140, 0, 1);*/
}

.__markApproval {
  /*background: rgba(0, 250, 230, 0.15);
  border-bottom: 2px solid rgba(0, 250, 230, 0.4);
  padding-bottom: 2px;*/
  background: rgba(0, 255, 255, 1.0);
}

.__markApproval.selected {
  /*
  background: rgba(0, 250, 230, 0.4);
  border-bottom: 2px solid rgba(0, 250, 230, 1);*/
  background: rgba(0, 255, 255, 1.0);
}

.__markParam {
  background: rgba(75, 140, 245, 0.15);
  border-bottom: 2px solid rgba(75, 140, 245, 0.3);
  padding-bottom: 2px;
}

.__markParam.selected {
  background: rgba(75, 140, 245, 0.20);
  border-bottom: 2px solid rgba(75, 140, 245, 0.5);
}

.__markOverlapC {
  background: rgba(255, 212, 0, 0.3);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
  padding-bottom: 2px;
}

.__markOverlapC.selected {
  background: rgba(255, 212, 0, 0.7);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}

.__markOverlapIC {
  background: rgba(247, 140, 0, 0.3);
  border-bottom: 2px solid rgba(247, 140, 0, 0.7);
  padding-bottom: 2px;
}

.__markOverlapIC.selected {
  background: rgba(247, 140, 0, 0.7);
  border-bottom: 2px solid rgba(247, 140, 0, 0.7);
}

.__markOverlapAP {
  background: rgba(0, 250, 230, 0.3);
  border-bottom: 2px solid rgba(0, 250, 230, 0.7);
  padding-bottom: 2px;
}

.__markOverlapAP.selected {
  background: rgba(0, 250, 230, 0.7);
  border-bottom: 2px solid rgba(0, 250, 230, 0.7);
}

.__markOverlapPA {
  background: rgba(75, 140, 245, 0.3);
  border-bottom: 2px solid rgba(75, 140, 245, 0.7);
  padding-bottom: 2px;
}

.__markOverlapPA.selected {
  background: rgba(75, 140, 245, 0.7);
  border-bottom: 2px solid rgba(75, 140, 245, 0.7);
}

/*
.CommentPlugin_CommentsPanel_List_DeleteButton i.delete {
  background-size: contain;
  position: absolute;
  left: 5px;
  top: 5px;
  height: 15px;
  width: 15px;
  vertical-align: -10px;
  background-image: url(../images/icons/trash3.svg);
  transition: opacity 0.2s linear;
}*/

#mentions-typeahead {
  position: fixed;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 24px 0px;
  border-radius: 0px;
  z-index: 1500;
}

#mentions-typeahead ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 0px;
}

#mentions-typeahead ul li {
  padding: 10px 20px;
  margin: 0;
  min-width: 180px;
  font-size: 15px;
  outline: none;
  cursor: pointer;
  border-radius: 0px;
}

#mentions-typeahead ul li.selected {
  background: #7243dd;
  color: #fff;
  font-weight: 600;
}